import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import Vimeo from "../ui/Vimeo";

interface esteveContent {
  type: "image" | "video" | "none";
  url?: string;
  video?: string;
  title?: string;
  caption?: string;
}

const Esteve: React.FC = () => {
  const [search] = useSearchParams();
  const image = parseInt(search.get("image") as string, 10);
  const video = parseInt(search.get("video") as string, 10);
  const mappingImage: esteveContent[] = [
    { type: "none" },
    {
      type: "image",
      url: "https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/respiracion.jpg",
      title: "Respiratión",
    },
    {
      type: "image",
      url: "https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/RESPIRACION%20POPEYE.jpg",
      title: "Respiratión Popeye",
      caption: "Steve bow wave breathing",
    },
    {
      type: "image",
      url: "https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/batido%20de%20rodilla.jpeg",
      title: "Batido De Rodilla",
    },
    {
      type: "image",
      url: "https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/BATIDO%20CORRECTO.jpeg",
      title: "Batido Correcto",
    },
    {
      type: "image",
      url: "https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/PATADA%20BALLET.jpeg",
      title: "Patada Ballet",
    },
    {
      type: "image",
      url: "https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/ENTRADA%20AL%20AGUA.jpeg",
      title: "Entrada Al Agua",
    },
    {
      type: "image",
      url: "https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/ENTRADA%20AL%20AGUA%20CORRECTA.jpg",
      title: "Entrada Al Agua Correcto",
    },
    {
      type: "image",
      url: "https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/TRACCION-CONTINUA.jpeg",
      title: "Tracción Continua",
    },
  ];

  const mappingVideo: esteveContent[] = [
    { type: "none" },
    {
      type: "video",
      video: "107312664",
      title: "Sink Downs",
      caption: "",
    },
    {
      type: "video",
      video: "102876188",
      title: "Scull #1",
      caption: "",
    },
    {
      type: "video",
      video: "102734926",
      title: "6-1-6",
      caption: "",
    },
    {
      type: "video",
      video: "302244918",
      title: "6 beat kick",
      caption: "",
    },
    {
      type: "video",
      video: "302244928",
      title: "2 beat kick",
      caption: "",
    },
    {
      type: "video",
      video: "302244881",
      title: "Big body rotation",
      caption: "",
    },
    {
      type: "video",
      video: "302244868",
      title: "Short body rotation",
      caption: "",
    },
    {
      type: "video",
      video: "111685513",
      title: "Realizar un batido excesivo",
      caption: "",
    },
  ];

  try {
    if (isNaN(image) && isNaN(video)) {
      return <Text>Error NaNs</Text>;
    }

    if (mappingImage[image] && mappingImage[image].type === "image") {
      return (
        <VStack w="full">
          <Heading pb={2}>{mappingImage[image].title}</Heading>
          <Image src={mappingImage[image].url} />
          <Text>{mappingImage[image].caption}</Text>
          <Enrique />
        </VStack>
      );
    }

    if (mappingVideo[video] && mappingVideo[video].type === "video") {
      return (
        <VStack w="full">
          <Heading pb={2}>{mappingVideo[video].title}</Heading>
          <Box>
            <Vimeo
              video={{
                source: "vimeo",
                id: mappingVideo[video].video as string,
                title: mappingVideo[video].title as string,
              }}
            />
          </Box>
          <Text>{mappingVideo[video].caption}</Text>
          <Enrique />
        </VStack>
      );
    }

    return <Text>Error</Text>;
  } catch (error: any) {
    return <Text>{error}</Text>;
  }
};

const Enrique = () => {
  return (
    <>
      <Heading pt={5}>Enrique Planelles Marcos</Heading>
      <HStack alignItems="flex-start">
        <VStack alignItems="flex-start">
          <Text mb={2}>
            Enrique es nuestro entrenador en Alicante, especialista en
            prepararte para travesías de aguas abiertas, triatlones y natación.
            Enrique es entrenador de triatlón Nivel III, tiene la Licenciatura
            en Ciencias de la Actividad Fïsica y el deporte y un Master de Alto
            Rendimiento deportivo por el Comité Olímpico Español.
          </Text>
          <Text mb={2}>
            Enrique descubrió Swim Smooth whuile entrenaba en el club London
            Fields de Londres y se volvió un apasionado sobre nuestros métodos
            de entrenamiento desde entonces. Enrique es responsable de la
            técnica de natación en el equipo profesional de triatlón de la
            Universidad de Alicante dónde se encarga de varios deportistas ITU
            como el campeón de triatlón en distancia olímpica de Alemania y
            España, además dirige un grupo de entrenamiento con triatletas de
            nivel nacional y se dedica a realizar análisis biomecánicos de
            natación por toda España.
          </Text>
          <Text>
            <Button
              variant="link"
              onClick={() => {
                window.open("https://enriqueplanellesswimsmooth.com")?.focus();
              }}
            >
              enriqueplanellesswimsmooth.com
            </Button>
          </Text>
        </VStack>
        <Image src="https://storage.googleapis.com/alpha-resources-swimsmooth/esteve/enrique.jpg" />
      </HStack>
    </>
  );
};

export { Esteve };
