import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { activity } from "../../types/activity";
import {
  formatDuration,
  formatPace,
  outputActivityDate,
  outputActivityDateShort,
} from "../../utils/dateHelper";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import { SingleValueWithUnitDisplay } from "../ui/SingleValueWithUnitDisplay";
import { ActivityLinkedIcon } from "./ActivityLinkedIcon";
import { ActivityLocationType } from "./ActivityLocationType";
import { ActivityProcessingProgress } from "./ActivityProcessingProgress";
import { ActivityProvider } from "./ActivityProvider";
import { InsightsPresentGraph } from "./InsightsPresentGraph";
import { PacingScoreGraph } from "./PacingScoreGraph";
interface ActivityListItemItemProps extends BoxProps {
  activity: activity;
}

const ActivityListItem: React.FC<ActivityListItemItemProps> = ({
  activity,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );

  const navigate = useNavigate();
  console.log(activity.data.summary.counts.insightGroups);
  const sizedContent = useBreakpointValue({
    base: (
      <Box
        id={"ACTIVITY_" + activity.id}
        key={"ACTIVITY_" + activity.id}
        bg={boxColorMode}
        mb={2}
        borderRadius={10}
        w="full"
        cursor="pointer"
        onClick={() =>
          navigate("/activities/" + activity.id, { replace: false })
        }
      >
        <HStack>
          <VStack align="flex-start" px={3} py={1} mb={2} w="full">
            <SimpleGrid columns={2} columnGap={5} w="full">
              <GridItem colSpan={2} justifySelf={"center"}>
                <ActivityProvider
                  provider={activity.source.provider}
                  size={50}
                />
              </GridItem>
              <GridItem colSpan={2} justifySelf={"left"}>
                <Text fontSize="xs">
                  <b>{outputActivityDateShort(activity.data.summary.dates)}</b>
                </Text>
                <Heading size="md" as="h3" mb={2}>
                  {activity.data.name}
                </Heading>
              </GridItem>
              <GridItem>
                <SingleValueWithUnitDisplay
                  value={activity.data.summary.distances.total}
                  label={"Distance"}
                  unit={activity.data.location.lapUnit}
                />
              </GridItem>
              <GridItem>
                <SingleValueDisplay
                  value={formatDuration(activity.data.summary.timings.total)}
                  label={"Time"}
                />
              </GridItem>
              <GridItem>
                <SingleValueDisplay
                  value={activity.data.summary.stss}
                  label={"sTSS"}
                />
              </GridItem>
              <GridItem>
                <SingleValueWithUnitDisplay
                  value={formatPace(activity.data.summary.pace.avg)}
                  label={"Pace"}
                  unit={"/ 100" + activity.data.location.lapUnit}
                />
              </GridItem>
            </SimpleGrid>
          </VStack>
        </HStack>
      </Box>
    ),
    md: (
      <Box
        id={"ACTIVITY_" + activity.id}
        key={"ACTIVITY_" + activity.id}
        bg={boxColorMode}
        mb={2}
        borderRadius={10}
        w="full"
        cursor="pointer"
        onClick={() =>
          navigate("/activities/" + activity.id, { replace: false })
        }
      >
        <HStack>
          <Image
            src={activity.data.summary.images.list}
            h={140}
            w={140}
            borderLeftRadius={10}
            fit="cover"
          />
          <VStack align="flex-start" p={2} w="full">
            <HStack w={"full"}>
              <Box w={"full"}>
                <Text fontSize="xs">
                  <b>{outputActivityDate(activity.data.summary.dates)}</b>
                </Text>
                <Heading size="md" as="h3" mt={2}>
                  {activity.data.name}
                </Heading>
              </Box>
              <VStack>
                <HStack>
                  <ActivityLinkedIcon completion={activity.data.completion} />
                  <ActivityLocationType
                    size={40}
                    locationType={activity.data.location.type}
                    timings={activity.data.summary.timings}
                  />
                  <PacingScoreGraph
                    size={40}
                    score={activity.data.summary.execution.score}
                  />
                  <InsightsPresentGraph
                    number={activity.data.summary.counts.insightGroups}
                    size={40}
                  />
                </HStack>
                <ActivityProcessingProgress activity={activity} />
              </VStack>
            </HStack>
            <Grid templateColumns="repeat(6, 0.5fr)" w="full">
              <GridItem>
                <SingleValueWithUnitDisplay
                  value={activity.data.summary.distances.total}
                  label={"Total Distance"}
                  unit={activity.data.location.lapUnit}
                />
              </GridItem>
              <GridItem>
                <SingleValueDisplay
                  value={formatDuration(activity.data.summary.timings.total)}
                  label={"Total Time"}
                />
              </GridItem>
              <GridItem mr={10}>
                <SingleValueDisplay
                  value={activity.data.summary.stss}
                  label={"sTSS"}
                />
              </GridItem>
              <GridItem mr={10} colSpan={2}>
                <SingleValueWithUnitDisplay
                  value={formatPace(activity.data.summary.pace.avg)}
                  label={"Average Pace"}
                  unit={"/ 100" + activity.data.location.lapUnit}
                />
              </GridItem>
              <GridItem justifySelf={"right"}>
                <ActivityProvider
                  provider={activity.source.provider}
                  size={50}
                />
              </GridItem>
            </Grid>
          </VStack>
        </HStack>
      </Box>
    ),
  });

  if (!activity.processed.wearable && !activity.processed.overall) {
    return null;
  }

  return sizedContent;
};

export { ActivityListItem };
