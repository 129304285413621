import { Box, HStack, Heading, VStack } from "@chakra-ui/react";
import * as contentful from "contentful";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  selectSessionTypesState,
  updateSessionTypesState,
} from "../../app/features/session/sessionTypesSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SessionList } from "../../components/sessions/SessionList";
import { ContentfulBodyProcessor } from "../../components/ui/Contentful";
import Loading from "../../components/ui/Loading";
import Vimeo from "../../components/ui/Vimeo";
import { sessionType } from "../../types/session";

const client = contentful.createClient({
  space: "50b15ahactsg",
  accessToken: "rYtEmKRrWoeFQM4pR8Ot8SZV7uC8OERTel461AQ3kvk",
});

const SessionsPage = () => {
  const { sessionType: sessionTypeParam } = useParams<{
    sessionType: string;
  }>();
  const { sessionTypes, lastUpdated: lastUpdatedSessionTypes } = useAppSelector(
    selectSessionTypesState
  );
  const [sessionType, setSessionType] = useState<sessionType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getEntriesLocal = async () => {
      const entries = await client.getEntries({
        content_type: "sessionType",
      });
      dispatch(
        updateSessionTypesState({
          sessionTypes: entries.items
            .map((a: any) => {
              return {
                code: a.fields.code,
                title: a.fields.title,
                description: a.fields.description,
                image: a.fields.image.fields.file.url,
                vimeoId: a.fields.vimeoId,
                levels: a.fields.levels,
                order: a.fields.order,
              };
            })
            .sort((a: any, b: any) => {
              return a.order - b.order;
            }),
        })
      );
      setLoading(false);
    };
    if (lastUpdatedSessionTypes) {
      const test = new Date(lastUpdatedSessionTypes).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!sessionTypes || sessionTypes.length === 0 || anHourAgo > test) {
        getEntriesLocal();
      } else {
        setLoading(false);
      }
    } else {
      getEntriesLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionTypes && sessionTypeParam) {
      const selectedSessionType = sessionTypes.filter(
        (st: sessionType) => st.code === sessionTypeParam
      );
      if (selectedSessionType.length === 1) {
        setSessionType(selectedSessionType[0]);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionTypeParam, sessionTypes]);

  if (!sessionTypeParam) {
    return <Navigate to={{ pathname: "/sessions" }} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!sessionType) {
    return <Navigate to={{ pathname: "/sessions" }} />;
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        {sessionType.title}
      </Heading>
      {sessionType.vimeoId && (
        <HStack w="full" mb={5}>
          <Box w={"40%"} mr={5}>
            <Vimeo
              video={{
                source: "vimeo",
                id: sessionType.vimeoId,
                title: sessionType.title,
              }}
            />
          </Box>
          <VStack w="full" alignItems="flex-start">
            <ContentfulBodyProcessor
              content={sessionType.description}
              contentIndex={0}
            />
          </VStack>
        </HStack>
      )}
      {!sessionType.vimeoId && (
        <HStack w="full" alignItems="flex-start" mb={5}>
          <VStack w="full" alignItems="flex-start">
            <ContentfulBodyProcessor
              content={sessionType.description}
              contentIndex={0}
            />
          </VStack>
        </HStack>
      )}
      <SessionList sessionType={sessionType} />
    </VStack>
  );
};

export { SessionsPage };
