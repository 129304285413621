import { BoxProps, Heading, HStack, Image, Link, Text } from "@chakra-ui/react";

interface GetAppsProps extends BoxProps {}

const GetApps: React.FC<GetAppsProps> = () => {
  const buttonWidth = "175px";
  return (
    <>
      <Heading size="md">The Swim Smooth Guru Apps</Heading>
      <Text mb={5}>
        The Swim Smooth Guru apps are required to access Stroke Insights&#8471;
        on your Apple Watch or compatible Garmin device.
      </Text>
      <HStack w={"full"} alignItems={"flex-start"}>
        <Link
          href="https://apps.apple.com/us/app/swim-smooth/id1516271521"
          cursor={"pointer"}
          isExternal
        >
          <Image
            src={"/marketing/AppStore-Black.svg"}
            alt="Download on the App Store"
            w={buttonWidth}
            maxW={buttonWidth}
            h="auto"
          />
        </Link>

        <Link
          href="https://apps.garmin.com/en-US/apps/b4ea2e83-ec07-4fec-80ad-6b1b67f951d0"
          cursor={"pointer"}
          isExternal
        >
          <Image
            src={"/marketing/CIQ-Black.svg"}
            alt="Download on Garmin ConnectIQ"
            w={buttonWidth}
            maxW={buttonWidth}
            h="auto"
          />
        </Link>
      </HStack>
    </>
  );
};

export { GetApps };
