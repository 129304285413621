import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuthState } from "../../app/features/auth/authSlice";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { CreateSupportTicket } from "../../components/ui/CreateSupportTicket";
import { CustomToast } from "../../components/ui/CustomToast";
import { PasswordInput } from "../../components/ui/FormControls";
import { forcedChangePassword } from "../../DataAccess/passwords";
import { createToast } from "../../utils/toastHelper";

const ForceChangePasswordPage = () => {
  const toast = useToast();
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setBreadcrumbLinks(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control: controlPassword,
    reset: resetPassword,
    handleSubmit: handleSubmitPassword,
    formState: {
      errors: errorsPassword,
      isSubmitting: isSubmittingPassword,
      isValid: isValidPassword,
      isDirty: isDirtyPassword,
    },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
    mode: "all",
  });

  const handlePassword = async (data: any): Promise<void> => {
    try {
      if (data.newPassword) {
        await forcedChangePassword(data.currentPassword, data.newPassword);
        dispatch(
          updateAuthState({
            forceChangePassword: false,
          })
        );
        resetPassword();
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Change Password"}
              status={"Success"}
              toast={toast}
              toastId={props.id}
            >
              <Text>Your password has been succesfully updated.</Text>
            </CustomToast>
          );
        });
        navigate("/", { replace: false });
      }
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Change Password"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to change password, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  return (
    <VStack
      as="form"
      onSubmit={handleSubmitPassword(handlePassword)}
      w="full"
      alignItems="flex-start"
      mb={5}
    >
      <Heading as="h3" size="md">
        Change Password (Required)
      </Heading>
      <Text>
        You are required to change your password to continue using the Guru.
        This could be due to you using a temporary password or because we have
        reset your password for you. Either way please enter something
        memorable, different from your current password, and at least 8
        charachters long.
      </Text>
      <FormControl pb={1}>
        <FormLabel>
          Current Password
          {errorsPassword.currentPassword && (
            <span className="formError">
              {errorsPassword.currentPassword.message}
            </span>
          )}
        </FormLabel>
        <Controller
          control={controlPassword}
          rules={{ required: "Current Password is required" }}
          name="currentPassword"
          render={({ field: { ref, ...restField } }) => (
            <PasswordInput autoComplete="current-password" {...restField} />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          New Password
          {errorsPassword.newPassword && (
            <span className="formError">
              {errorsPassword.newPassword.message}
            </span>
          )}
        </FormLabel>
        <Controller
          control={controlPassword}
          rules={{ required: "New Password is required" }}
          name="newPassword"
          render={({ field: { ref, ...restField } }) => (
            <PasswordInput autoComplete="new-password" {...restField} />
          )}
        />
      </FormControl>
      <Button
        isDisabled={!isDirtyPassword || !isValidPassword}
        isLoading={isSubmittingPassword}
        type="submit"
        mt={5}
      >
        Set New Password
      </Button>
    </VStack>
  );
};

export { ForceChangePasswordPage };
