import axios from "axios";
import { default as Stripe } from "stripe";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";

const setUserStripeCustomer = async (
  userId: string
): Promise<string | null> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "stripe/customer/" + userId
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const setUserStripeSubscription = async (
  userId: string
): Promise<string | null> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "stripe/subscription/" + userId
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const getCheckoutSession = async (
  customerSessionId: string
): Promise<Stripe.Checkout.Session | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL +
        "stripe/customer/session/" +
        customerSessionId
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const createUserStripeCustomerSession =
  async (): Promise<Stripe.CustomerSession | null> => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_ROOT_URL + "stripe/customer/session"
      );
      if (result.data.success) {
        return result.data.payload;
      }
    } catch (error: any) {
      console.log(error);
      throw new Error(processSwimSmoothApiError(error));
    }
    return null;
  };

const deleteStripeSubscription = async (): Promise<any> => {
  try {
    const result = await axios.delete(
      process.env.REACT_APP_API_ROOT_URL + "stripe/subscription"
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
};

const cancelStripeSubscription = async (): Promise<any> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "stripe/subscription/cancel"
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
};

const resumeStripeSubscription = async (): Promise<any> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "stripe/subscription/resume"
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
};

export {
  cancelStripeSubscription,
  createUserStripeCustomerSession,
  deleteStripeSubscription,
  getCheckoutSession,
  resumeStripeSubscription,
  setUserStripeCustomer,
  setUserStripeSubscription,
};
