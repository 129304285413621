import { Box, Center } from "@chakra-ui/react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  activityAnalysisSwimtelligenceInsightGroupSection,
  activityAnalysisSwimtelligenceInsightGroupSectionDataVideos,
} from "../../types/activity";
import VimeoContainer from "../ui/VimeoContainer";

interface StrokeInsightCarouselProps {
  videos: activityAnalysisSwimtelligenceInsightGroupSection[];
}

const StrokeInsightCarousel: React.FC<StrokeInsightCarouselProps> = ({
  videos,
}) => {
  return (
    <Box pt={5} w="full">
      <Swiper
        autoHeight={true}
        pagination={{
          type: "bullets",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="setSwiper"
      >
        {videos.map(
          (video: activityAnalysisSwimtelligenceInsightGroupSection) => {
            const data: activityAnalysisSwimtelligenceInsightGroupSectionDataVideos =
              video.data as activityAnalysisSwimtelligenceInsightGroupSectionDataVideos;
            return data.videos.map((video, pageIndex) => {
              return (
                <SwiperSlide key={pageIndex}>
                  <Box mx={50} pb={35}>
                    <Center>
                      <VimeoContainer
                        video={video}
                        title={video.title ? video.title : ""}
                        videoWidth={600}
                      />
                    </Center>
                  </Box>
                </SwiperSlide>
              );
            });
          }
        )}
      </Swiper>
    </Box>
  );
};

export { StrokeInsightCarousel };
