import { BoxProps, Text, VStack } from "@chakra-ui/react";
import { billing } from "../../../../types/billing";
import { TrialDetails } from "../TrialDetails";
import { StripePricingTable } from "../stripe/StripePricingTable";
interface SubscriptionDetailsNoneProps extends BoxProps {
  billing: billing;
}

const SubscriptionDetailsNone: React.FC<SubscriptionDetailsNoneProps> = ({
  billing,
}) => {
  return (
    <VStack w="full" alignItems="start">
      <Text>
        You are currently using our free 'Guidance' level. There are loads of
        reasons to upgrade, check out the features you're missing out on below.
      </Text>
      <TrialDetails trial={billing.trial} />
      <StripePricingTable />
    </VStack>
  );
};

export { SubscriptionDetailsNone };
