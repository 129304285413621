import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  BoxProps,
  Button,
  Heading,
  SimpleGrid,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { selectAuthState } from "../../../../app/features/auth/authSlice";
import { useAppSelector } from "../../../../app/hooks";
import {
  cancelStripeSubscription,
  resumeStripeSubscription,
} from "../../../../DataAccess/stripe";
import { billing, billingSubscription } from "../../../../types/billing";
import { checkRole, forceRefreshToken } from "../../../../utils/authHelper";
import { createToast } from "../../../../utils/toastHelper";
import { CreateSupportTicket } from "../../../ui/CreateSupportTicket";
import { CustomToast } from "../../../ui/CustomToast";
import { SingleValueDisplay } from "../../../ui/SingleValueDisplay";
import { TrialDetails } from "../TrialDetails";
import { StripePricingTable } from "./StripePricingTable";

interface SubscriptionDetailsStripeProps extends BoxProps {
  billing: billing;
}

const SubscriptionDetailsStripe: React.FC<SubscriptionDetailsStripeProps> = ({
  billing,
}) => {
  const subscription: billingSubscription =
    billing.subscription as billingSubscription;
  const now = new Date();
  const currentPeriodStart = new Date(subscription.currentPeriodStart);
  const currentPeriodEnd = new Date(subscription.currentPeriodEnd);
  // "active" | "past_due" | "unpaid" | "cancelled" | "incomplete" | "incomplete_expired" | "trialing" | "paused"
  const status = subscription.status;
  const isExpired = currentPeriodEnd < now;
  const isCancelled = subscription.cancelled;
  const isPaymentIssue =
    status === "past_due" ||
    status === "unpaid" ||
    status === "incomplete_expired";
  const isPaused = status === "paused";

  const activeColour = useColorModeValue("green.500", "green.300");
  const expiredColour = useColorModeValue("red.500", "red.300");
  const cancelledColour = useColorModeValue("orange.500", "orange.300");

  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const cancelRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );

  const toast = useToast();
  const dispatch = useDispatch();

  const forceRefreshLocal = async () => {
    await forceRefreshToken(dispatch);
  };

  const handleCancel = async (): Promise<void> => {
    toast.closeAll();
    if (subscription) {
      try {
        setIsCanceling(true);
        await cancelStripeSubscription();
        await forceRefreshLocal();
        setIsCanceling(false);
        onClose();
      } catch (error: any) {
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Cancel Subscription"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Unable to cancel subscription, please contact support.
              </Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  const handleResume = async (): Promise<void> => {
    toast.closeAll();
    if (subscription) {
      try {
        setIsCanceling(true);
        await resumeStripeSubscription();
        await forceRefreshLocal();
        setIsCanceling(false);
        onClose();
      } catch (error: any) {
        createToast(toast, (props: any) => {
          return (
            <CustomToast
              title={"Cancel Subscription"}
              status={"Error"}
              toast={toast}
              toastId={props.id}
            >
              <Text>
                Unable to cancel subscription, please contact support.
              </Text>
              <CreateSupportTicket />
            </CustomToast>
          );
        });
      }
    }
  };

  return (
    <VStack w="full" alignItems="start">
      <VStack w="full" alignItems="flex-start">
        {subscription && (
          <>
            {isPaused ? (
              <Heading as="h3" size="md">
                You{" "}
                <Text as={"span"} color={cancelledColour}>
                  have
                </Text>{" "}
                {subscription.product.price.interval === "year"
                  ? "an annual"
                  : "a monthly"}{" "}
                '{subscription.level}' subscription{" "}
                <Text as={"span"} color={cancelledColour}>
                  which is currently paused
                </Text>
              </Heading>
            ) : isPaymentIssue ? (
              <Heading as="h3" size="md">
                You{" "}
                <Text as={"span"} color={expiredColour}>
                  have
                </Text>{" "}
                {subscription.product.price.interval === "year"
                  ? "an annual"
                  : "a monthly"}{" "}
                '{subscription.level}' subscription{" "}
                <Text as={"span"} color={expiredColour}>
                  which has experienced payment issues
                </Text>
              </Heading>
            ) : isExpired ? (
              <Heading as="h3" size="md">
                You{" "}
                <Text as={"span"} color={expiredColour}>
                  had
                </Text>{" "}
                {subscription.product.price.interval === "year"
                  ? "an annual"
                  : "a monthly"}{" "}
                '{subscription.level}' subscription{" "}
                <Text as={"span"} color={expiredColour}>
                  which expired{" "}
                  {new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(currentPeriodEnd)}
                </Text>
              </Heading>
            ) : isCancelled ? (
              <Heading as="h3" size="md">
                You{" "}
                <Text as={"span"} color={cancelledColour}>
                  have
                </Text>{" "}
                {subscription.product.price.interval === "year"
                  ? "an annual"
                  : "a monthly"}{" "}
                '{subscription.level}' subscription{" "}
                <Text as={"span"} color={cancelledColour}>
                  which is due to expire{" "}
                  {new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(currentPeriodEnd)}
                </Text>
              </Heading>
            ) : (
              <Heading as="h3" size="md">
                You{" "}
                <Text as={"span"} color={activeColour}>
                  have
                </Text>{" "}
                {subscription.product.price.interval === "year"
                  ? "an annual"
                  : "a monthly"}{" "}
                '{subscription.level}' subscription{" "}
                <Text as={"span"} color={activeColour}>
                  which will renew{" "}
                  {new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(currentPeriodEnd)}
                </Text>
              </Heading>
            )}
            <Text>{billing.subscriptionDescription}</Text>
            <SimpleGrid
              w="full"
              columns={[1, 1, 6, 6]}
              columnGap={2}
              rowGap={2}
            >
              <SingleValueDisplay value={subscription.level} label="Level" />
              <SingleValueDisplay
                value={subscription.customer}
                label="Customer"
              />
              <SingleValueDisplay
                value={subscription.id}
                label="Subscription"
              />
              <SingleValueDisplay
                value={new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(currentPeriodStart)}
                label={"Current Period Start"}
              />
              <SingleValueDisplay
                value={new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(currentPeriodEnd)}
                label={
                  isExpired
                    ? "Expired"
                    : isCancelled
                    ? "Expiry Date"
                    : "Auto-Renewal Date"
                }
              />
              <SingleValueDisplay
                value={status
                  .replace("_", " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
                label="Status"
              />
            </SimpleGrid>
          </>
        )}
        <TrialDetails trial={billing.trial} />
      </VStack>
      {!isExpired && (
        <>
          <Heading as="h3" size="md" mt={5}>
            Manage Your Subscription & Payment Details
          </Heading>
          <Text>
            Swim Smooth do not store or manage any of your payment details. This
            is all handled by our payment provider Stripe. Click the button
            below to access the secure Stripe portal to manage you subscriptions
            and payment methods. When asked to enter your email address you will
            need to use the email address you used when you created your
            subscription.
          </Text>
          <SimpleGrid columns={3} w={"full"} mt={5}>
            {subscription && (
              <Button
                variant="success"
                px={10}
                onClick={() => {
                  window.open(process.env.REACT_APP_STRIPE_PORTAL)?.focus();
                }}
              >
                Manage Subscription with Stripe
              </Button>
            )}
            <Spacer />
            {subscription && !isCancelled && (
              <>
                <Button
                  variant="warning"
                  isLoading={isCanceling}
                  onClick={onOpen}
                >
                  Cancel Subscription
                </Button>
                <AlertDialog
                  isOpen={isOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={onClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Cancel Subscription
                      </AlertDialogHeader>
                      <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                      </AlertDialogBody>
                      <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                          Back
                        </Button>
                        <Button
                          variant="warning"
                          px={20}
                          isLoading={isCanceling}
                          onClick={handleCancel}
                          ml={3}
                        >
                          Cancel Subscription
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
            )}
            {subscription && isCancelled && (
              <>
                <Button
                  variant={"success"}
                  isLoading={isCanceling}
                  onClick={onOpen}
                >
                  Resume Subscription
                </Button>
                <AlertDialog
                  isOpen={isOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={onClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Resume Subscription
                      </AlertDialogHeader>
                      <AlertDialogBody>
                        Are you sure? You can't undo this action afterwards.
                      </AlertDialogBody>
                      <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                          Back
                        </Button>
                        <Button
                          variant="success"
                          px={20}
                          isLoading={isCanceling}
                          onClick={handleResume}
                          ml={3}
                        >
                          Resume Subscription
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
            )}
          </SimpleGrid>
        </>
      )}
      {isExpired && (
        <>
          <Heading as="h3" size="md" mt={5}>
            Resubscribe
          </Heading>
          <StripePricingTable />
        </>
      )}
      {(process.env.REACT_APP_ENV === "alpha" ||
        checkRole(accessToken, "*ALL", "superadmin")) && (
        <pre>
          <code>{JSON.stringify(subscription, null, 2)}</code>
        </pre>
      )}
    </VStack>
  );
};

export { SubscriptionDetailsStripe };
