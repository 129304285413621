import {
  Badge,
  Box,
  BoxProps,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import {
  activityAnalysisSwimtelligenceInsightGroup,
  activityAnalysisSwimtelligenceInsightGroupSection,
  activityAnalysisSwimtelligenceInsightGroupSectionDataImpact,
  activityAnalysisSwimtelligenceInsightGroupSectionDataTips,
} from "../../types/activity";
import Loading from "../ui/Loading";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import Vimeo from "../ui/Vimeo";
import { StrokeInsightCarousel } from "./StrokeInsightCarousel";

interface StrokeInsightGroupExtraProps extends BoxProps {
  displayedInsightGroup: activityAnalysisSwimtelligenceInsightGroup;
}

const StrokeInsightGroupExtra: React.FC<StrokeInsightGroupExtraProps> = ({
  displayedInsightGroup,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tips, setTips] = useState<
    activityAnalysisSwimtelligenceInsightGroupSection[]
  >([]);
  const [impacts, setImpacts] = useState<
    activityAnalysisSwimtelligenceInsightGroupSection[]
  >([]);
  const [videos, setVideos] = useState<
    activityAnalysisSwimtelligenceInsightGroupSection[]
  >([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const processed = displayedInsightGroup.sections.reduce(
      (
        previous: any,
        section: activityAnalysisSwimtelligenceInsightGroupSection,
        index: number
      ) => {
        if (section.type === "tips") {
          const data: activityAnalysisSwimtelligenceInsightGroupSection =
            section;
          previous.tips.push(data);
        } else if (section.type === "impact") {
          const data: activityAnalysisSwimtelligenceInsightGroupSection =
            section;
          previous.impacts.push(data);
        } else if (section.type === "videos") {
          const data: activityAnalysisSwimtelligenceInsightGroupSection =
            section;
          previous.videos.push(data);
        }
        return previous;
      },
      { tips: [], impacts: [], videos: [] }
    );
    setTips(processed.tips);
    setImpacts(processed.impacts);
    setVideos(processed.videos);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedInsightGroup]);

  if (loading) {
    return <Loading message="Loading insight recommendations" />;
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <HStack w="full" alignItems="flex-start">
        {tips.length > 0 &&
          tips.map((tips, index: number) => {
            const data: activityAnalysisSwimtelligenceInsightGroupSectionDataTips =
              tips.data as activityAnalysisSwimtelligenceInsightGroupSectionDataTips;
            return (
              <Box w="full" key={index}>
                <Heading as="h5" size="xs" mb={2}>
                  {tips.title}
                </Heading>
                <Grid templateColumns="repeat(1#, 1fr)" gap={2}>
                  {data.tips.map((tip, item) => {
                    return (
                      <GridItem key={item}>
                        <>
                          <Text>{tip.title}</Text>
                          <>
                            <Button
                              colorScheme="pink"
                              mt={2}
                              mb={5}
                              onClick={onOpen}
                            >
                              {tip.text}
                            </Button>
                            <Drawer
                              isOpen={isOpen}
                              placement="left"
                              size="md"
                              onClose={onClose}
                            >
                              <DrawerOverlay />
                              <DrawerContent>
                                <DrawerCloseButton />
                                <DrawerHeader>{tips.title}</DrawerHeader>
                                <DrawerBody>
                                  {tip.deck.map((item: any, index: number) => {
                                    return (
                                      <Fragment key={index}>
                                        {item.type === "image" &&
                                          item.image &&
                                          item.position === "top" && (
                                            <Image my={4} src={item.imageWeb} />
                                          )}
                                        <Text mb={4}>{item.text}</Text>
                                        {item.type === "image" &&
                                          item.image &&
                                          item.position === "bottom" && (
                                            <Image my={4} src={item.imageWeb} />
                                          )}
                                        {item.link &&
                                          item.link.type === "vimeo" && (
                                            <Box my={4}>
                                              <Vimeo
                                                video={{
                                                  source: "vimeo",
                                                  id: item.link.link,
                                                }}
                                              />
                                            </Box>
                                          )}
                                      </Fragment>
                                    );
                                  })}
                                </DrawerBody>
                              </DrawerContent>
                            </Drawer>
                          </>
                        </>
                      </GridItem>
                    );
                  })}
                </Grid>
              </Box>
            );
          })}
        {impacts.length > 0 &&
          impacts.map((impact, index: number) => {
            const data: activityAnalysisSwimtelligenceInsightGroupSectionDataImpact =
              impact.data as activityAnalysisSwimtelligenceInsightGroupSectionDataImpact;
            return (
              <Box w="full" key={index}>
                <Heading as="h5" size="xs" mb={2}>
                  {impact.title}
                </Heading>
                <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                  {data.metrics.map((metric, itemM) => {
                    return (
                      <GridItem key={"M" + itemM}>
                        <SingleValueDisplay
                          value={metric.text}
                          label={metric.title}
                        />
                      </GridItem>
                    );
                  })}
                  {data.comments.map((comment, itemC) => {
                    return (
                      <GridItem key={"C" + itemC}>
                        <Badge py={1} px={3} borderRadius={5} fontSize="0.7em">
                          {comment}
                        </Badge>
                      </GridItem>
                    );
                  })}
                </Grid>
              </Box>
            );
          })}
      </HStack>
      {videos.length > 0 && <StrokeInsightCarousel videos={videos} />}
    </VStack>
  );
};

export { StrokeInsightGroupExtra };
