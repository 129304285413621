import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { activity, activityAnalysis } from "../../types/activity";
import { checkRole } from "../../utils/authHelper";
import { ucFirst } from "../../utils/coreHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BetaBadge } from "../ui/BetaBadge";
import Loading from "../ui/Loading";

interface swimTypeProcess {
  name: string;
  desc: string;
  link: string;
}

const swimTypeProcesses: swimTypeProcess[] = [
  {
    name: "arnie",
    desc: "Taming the Arnie",
    link: "/library/5vQTdT9lHeaftvmax42Y36/4Ci4xDCaOCk9eTGMPjErFg/3VaOQtpzSipguUuO3hIqwe",
  },
  {
    name: "bambino",
    desc: "Boosting the Bambino",
    link: "/library/5vQTdT9lHeaftvmax42Y36/4Ci4xDCaOCk9eTGMPjErFg/7ryLNqs4f6ZbLI75UdcwJ9",
  },
  {
    name: "overglider",
    desc: "Curing the Overglider",
    link: "/library/5vQTdT9lHeaftvmax42Y36/4Ci4xDCaOCk9eTGMPjErFg/71Ulwa3zu1W6Wbog9DmEsT",
  },
  {
    name: "kicktastic",
    desc: "Inspiring the Kicktastic",
    link: "/library/5vQTdT9lHeaftvmax42Y36/4Ci4xDCaOCk9eTGMPjErFg/3u3iy9ifHkHIfeAqLd3kjp",
  },
  {
    name: "smooth",
    desc: "Motivating the Smooth",
    link: "/library/5vQTdT9lHeaftvmax42Y36/4Ci4xDCaOCk9eTGMPjErFg/1ch6b1tEm3HLveKkwXpreQ",
  },
  {
    name: "swinger",
    desc: "Supporting the Swinger",
    link: "/library/5vQTdT9lHeaftvmax42Y36/4Ci4xDCaOCk9eTGMPjErFg/3kYmhff9E352AjdXz8pds5",
  },
];

interface SwimTypesProps extends BoxProps {
  loading: boolean;
  activity: activity;
  activityAnalysis: activityAnalysis | null;
}

const SwimTypes: React.FC<SwimTypesProps> = ({
  loading,
  activity,
  activityAnalysis,
}) => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  if (!checkRole(accessToken, "swimTypes", "activity")) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
        <Heading as="h4" size="sm" mb={5}>
          Swim Type Analysis
          <BetaBadge />
        </Heading>
        <NotAuthorised functionText={"Swim Type Analysis"} size={"small"} />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
        <Heading as="h4" size="sm" mb={5}>
          Swim Type Analysis
          <BetaBadge />
        </Heading>
        <Loading message="" />
      </Box>
    );
  }

  if (!activityAnalysis) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
        <Heading as="h4" size="sm" mb={5}>
          Swim Type Analysis
          <BetaBadge />
        </Heading>
        <Text>Unable to load swim type analysis for this activity.</Text>
      </Box>
    );
  }

  if (
    !activityAnalysis.swimtelligence ||
    !activityAnalysis.swimtelligence.swimTypes
  ) {
    return (
      <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
        <Heading as="h4" size="sm" mb={5}>
          Swim Type Analysis
          <BetaBadge />
        </Heading>
        <Text>Unable to load swim type analysis for this activity.</Text>
      </Box>
    );
  }

  const data = activityAnalysis.swimtelligence.swimTypes.sort(
    (a, b) => b.value - a.value
  );

  return (
    <Box bg={boxColorMode} p={3} borderRadius={10} height="100%">
      <Heading as="h4" size="sm" mb={5}>
        Swim Type Analysis
        <>
          <Icon
            cursor="pointer"
            ml={2}
            mt={-4}
            as={InfoIcon}
            onClick={onOpen}
          />
          <Drawer isOpen={isOpen} placement="left" size="md" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>About Swim Types</DrawerHeader>
              <DrawerBody>
                <Text>
                  Swim Types is our way of recognising both individuality and
                  systemic clustering in the way people swim. It is likely that
                  you will display traits of several of our Swim Types to
                  varying degrees, such is the spectrum-like nature of the
                  paradigm. Follow the advice of the most likely Swim Type and
                  if in doubt, have one of our excellent coaches work with you
                  to determine a clear pathway forwards.
                </Text>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
        <BetaBadge />
      </Heading>
      <Table size={"sm"}>
        <Thead>
          <Tr>
            <Th>Swim Type</Th>
            <Th isNumeric>Likelihood</Th>
            <Th>Learn More</Th>
          </Tr>
        </Thead>
        <Tbody>
          {(Array.isArray(data) ? data : []).map((swimType, index) => {
            // Ensure swimType exists and has required properties
            if (
              !swimType ||
              typeof swimType.value !== "number" ||
              swimType.value === 0
            ) {
              return null;
            }

            // Find the corresponding process
            const process = swimTypeProcesses.find(
              (st) => st.name === swimType.name
            );

            // Ensure process exists before rendering
            if (!process) {
              return null;
            }

            return (
              <Tr key={swimType.name || index}>
                <Td>{ucFirst(swimType.name)}</Td>
                <Td isNumeric>{swimType.value}%</Td>
                <Td>
                  <Button
                    onClick={() => {
                      navigate(process.link);
                    }}
                    variant="link"
                  >
                    {process.desc} →
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
export { SwimTypes };
