import { Box, BoxProps, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import { activityDataCountsLeftRight } from "../../types/activity";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";

interface InsightsPresentGraphProps extends BoxProps {
  number: activityDataCountsLeftRight;
  size: number;
  upgrade?: boolean;
}

const InsightsPresentGraph: React.FC<InsightsPresentGraphProps> = ({
  number,
  size,
  upgrade,
  ...rest
}) => {
  console.log(number);
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const pathColour = useColorModeValue("#00B24E", "#35FC86");
  const textColour = useColorModeValue("#000000", "#FFFFFF");
  const trailColour = useColorModeValue("#AAAAAA", "#666666");

  if (!checkRole(accessToken, "executionScore", "activity")) {
    if (upgrade) {
      return <NotAuthorised functionText={"Session Pacing"} size={"small"} />;
    }
    return null;
  }

  if (number) {
    const totalScore = ((number.left + number.right) / 12) * 100;

    return (
      <Tooltip
        hasArrow
        label={`Left: ${number.left} of 6, Right: ${number.right} of 6`}
      >
        <Box style={{ width: size, height: size }} {...rest}>
          <CircularProgressbar
            value={totalScore}
            text={`${number.left}|${number.right}`}
            strokeWidth={10}
            styles={buildStyles({
              textSize: "30px",
              strokeLinecap: "butt",
              trailColor: trailColour,
              pathColor: pathColour,
              textColor: textColour,
            })}
          />
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip hasArrow label={`Left: 0 of 6, Right: 0 of 6`}>
      <Box style={{ width: size, height: size }} {...rest}>
        <CircularProgressbar
          value={0}
          text={`0|0`}
          strokeWidth={10}
          styles={buildStyles({
            textSize: "30px",
            strokeLinecap: "butt",
            trailColor: trailColour,
            pathColor: pathColour,
            textColor: textColour,
          })}
        />
      </Box>
    </Tooltip>
  );
};

export { InsightsPresentGraph };
