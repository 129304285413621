import { ChakraProvider, ColorModeScript, theme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./app/store";
import { ErrorBoundary } from "./components/generic/ErrorBoundary";
import { Router } from "./components/routes/Router";
import { NavigationSetter } from "./navigation";
import swimSmoothTheme from "./themes/swimsmooth/theme";

export const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={swimSmoothTheme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <BrowserRouter>
              <NavigationSetter />
              <Router />
            </BrowserRouter>
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};
