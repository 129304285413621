import { Button, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { decodeJwt } from "jose";
import React, { useEffect, useState } from "react";
import { getUserById, getUserFloDeskDataById } from "../../DataAccess/users";
import { selectAuthState } from "../../app/features/auth/authSlice";
import {
  selectCountryState,
  updateCountryState,
} from "../../app/features/location/countrySlice";
import {
  selectLocationState,
  updateLocationState,
} from "../../app/features/location/locationSlice";
import {
  selectUserState,
  updateUserState,
} from "../../app/features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SSAccessJWTPayloadRaw, SSRefreshJWTPayload } from "../../types/auth";
import { forceRefreshToken, getLevel } from "../../utils/authHelper";
import { formatDateTime } from "../../utils/dateHelper";
import { countryToCurrency, reverseGeocode } from "../../utils/locationHelper";
import { ChickenButton } from "../ui/ChickenButton";
import Loading from "../ui/Loading";

const Debug: React.FC = () => {
  const {
    access: accessToken,
    refresh: refreshToken,
    admin: adminToken,
  } = useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const {
    latitude,
    longitude,
    lastUpdated: lastUpdatedLocation,
  } = useAppSelector(selectLocationState);
  const {
    country,
    currency,
    lastUpdated: lastUpdatedCountry,
  } = useAppSelector(selectCountryState);
  const [loadingLocation, setLoadingLocation] = useState<boolean>(true);
  const [loadingCountry, setLoadingCountry] = useState<boolean>(true);
  const [accessTokenExpiry, setAccessTokenExpiry] = useState<string | null>(
    null
  );
  const [refreshTokenExpiry, setRefreshTokenExpiry] = useState<string | null>(
    null
  );
  const [adminTokenExpiry, setAdminTokenExpiry] = useState<string | null>(null);
  const [floDeskData, setFloDeskData] = useState<any>({});
  const [accessTokenPayload, setAccessTokenPayload] =
    useState<SSAccessJWTPayloadRaw>();
  const [refreshTokenPayload, setRefreshTokenPayload] =
    useState<SSRefreshJWTPayload>();
  const [adminTokenPayload, setAdminTokenPayload] =
    useState<SSRefreshJWTPayload>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getUserLocal = async () => {
      if (user) {
        const userResponse = await getUserById(user._id);
        if (userResponse) {
          dispatch(updateUserState(userResponse));
          try {
            const userFlodeskResponse = await getUserFloDeskDataById(user._id);
            if (userFlodeskResponse) {
              setFloDeskData(userFlodeskResponse);
            }
          } catch (error: any) {
            // Do nothing
          }
        }
      }
    };
    getUserLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get the users location
    const getLocationLocal = async () => {
      if ("geolocation" in navigator) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              if (position && position.coords) {
                dispatch(
                  updateLocationState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  })
                );
              } else {
                dispatch(updateLocationState(null));
              }
            },
            (error: any) => {
              dispatch(updateLocationState(null));
            }
          );
        } catch (error: any) {
          dispatch(updateLocationState(null));
        }
      } else {
        dispatch(updateLocationState(null));
      }
      setLoadingLocation(false);
    };
    if (lastUpdatedLocation) {
      const test = new Date(lastUpdatedLocation).getTime();
      const aDayAgo = new Date().getTime() - 24 * 1000 * 60 * 60;
      if (!latitude || !longitude || aDayAgo > test) {
        getLocationLocal();
      } else {
        setLoadingLocation(false);
      }
    } else {
      getLocationLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get the users location
    const getCountryLocal = async () => {
      if (user && latitude && longitude) {
        try {
          const country = await reverseGeocode(latitude, longitude);
          const currency = countryToCurrency(country);
          dispatch(
            updateCountryState({
              country: country,
              currency: currency,
            })
          );
        } catch (error: any) {
          setLoadingCountry(false);
        }
      } else {
        dispatch(updateCountryState(null));
      }
      setLoadingCountry(false);
    };
    if (lastUpdatedCountry) {
      const test = new Date(lastUpdatedCountry).getTime();
      const aDayAgo = new Date().getTime() - 24 * 1000 * 60 * 60;
      if (!country || !currency || aDayAgo > test) {
        getCountryLocal();
      } else {
        setLoadingCountry(false);
      }
    } else {
      getCountryLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, latitude, longitude]);

  useEffect(() => {
    // If admin decode Tokens to get expiries
    if (accessToken) {
      const at = decodeJwt(accessToken) as unknown as SSAccessJWTPayloadRaw;
      at.roles = at.roles.map((role: string) => {
        return JSON.parse(role);
      });
      setAccessTokenPayload(at);
      if (at && at.exp) {
        setAccessTokenExpiry(
          new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
          }).format(new Date(new Date(at.exp * 1000)))
        );
      } else {
        setAccessTokenExpiry(null);
      }
    }
    if (refreshToken) {
      const rt = decodeJwt(refreshToken) as unknown as SSRefreshJWTPayload;
      setRefreshTokenPayload(rt);
      if (rt && rt.exp) {
        setRefreshTokenExpiry(
          new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
          }).format(new Date(new Date(rt.exp * 1000)))
        );
      } else {
        setRefreshTokenExpiry(null);
      }
    }
    if (adminToken) {
      const aDt = decodeJwt(adminToken) as unknown as SSRefreshJWTPayload;
      setAdminTokenPayload(aDt);
      if (aDt && aDt.exp) {
        setAdminTokenExpiry(
          new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
          }).format(new Date(new Date(aDt.exp * 1000)))
        );
      } else {
        setAdminTokenExpiry(null);
      }
    }
  }, [accessToken, refreshToken, adminToken]);

  if (loadingLocation) {
    return <Loading message="Loading Location" />;
  }

  if (loadingCountry) {
    return <Loading message="Loading Country & Currency" />;
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <SimpleGrid columns={2} gap={5} mb={5}>
        <Button
          onClick={() => {
            forceRefreshToken(dispatch);
          }}
        >
          Refresh Now
        </Button>
        <ChickenButton label="Chicken Button" />
      </SimpleGrid>
      <SimpleGrid columns={2} columnGap={5} w={"full"}>
        <VStack w="full" alignItems="flex-start">
          <Heading size="sm">Auth</Heading>
          <Text>User: {user ? user._id : ""}</Text>
          <Text>At: {accessTokenExpiry}</Text>
          <Text>Rt: {refreshTokenExpiry}</Text>
          <Text>ADt: {adminTokenExpiry}</Text>
        </VStack>
        <VStack w="full" alignItems="flex-start">
          <Heading size="sm">Location</Heading>
          <Text>Location: {latitude + ", " + longitude}</Text>
          <Text>Location Refresh: {formatDateTime(lastUpdatedLocation)}</Text>
          <Text>Country: {country}</Text>
          <Text>Currency: {currency}</Text>
          <Text>
            Country / Currency Refresh:{formatDateTime(lastUpdatedCountry)}
          </Text>
        </VStack>
        <VStack w="full" alignItems="flex-start">
          <Heading size="sm">Billing</Heading>
          <Text>Source: {user?.data.billing.source}</Text>
          <Text>Level: {user?.data.billing.level}</Text>
          <Text>At Level: {getLevel(accessToken)}</Text>
          <Text>sStatus: {user?.data.billing.subscriptionStatus}</Text>
          <Text>tStatus: {user?.data.billing.trialStatus}</Text>
        </VStack>
      </SimpleGrid>
      <SimpleGrid columns={2} columnGap={5} w={"full"}>
        <VStack>
          <Heading size="sm">Access Token</Heading>
          <pre>
            <code>{JSON.stringify(accessTokenPayload, null, 2)}</code>
          </pre>
        </VStack>
        <VStack>
          <Heading size="sm">Refresh Token</Heading>
          <pre>
            <code>{JSON.stringify(refreshTokenPayload, null, 2)}</code>
          </pre>
          <Heading size="sm">Admin Token</Heading>
          <pre>
            <code>{JSON.stringify(adminTokenPayload, null, 2)}</code>
          </pre>
          <Heading size="sm">Audit Summary</Heading>
          <pre>
            <code>{JSON.stringify(user?.auditSummary, null, 2)}</code>
          </pre>
          <Heading size="sm">Billing</Heading>
          <pre>
            <code>{JSON.stringify(user?.data.billing, null, 2)}</code>
          </pre>
          <Heading size="sm">User Basic</Heading>
          <pre>
            <code>{JSON.stringify(user?.data.basic, null, 2)}</code>
          </pre>
          <Heading size="sm">Coaching</Heading>
          <pre>
            <code>{JSON.stringify(user?.data.coaching, null, 2)}</code>
          </pre>
          <Heading size="sm">Last</Heading>
          <pre>
            <code>{JSON.stringify(user?.last, null, 2)}</code>
          </pre>
          <Heading size="sm">Process</Heading>
          <pre>
            <code>{JSON.stringify(user?.processes, null, 2)}</code>
          </pre>
          <Heading size="sm">Settings</Heading>
          <pre>
            <code>{JSON.stringify(user?.settings, null, 2)}</code>
          </pre>
          <Heading size="sm">FloDesk Data</Heading>
          <pre>
            <code>{JSON.stringify(floDeskData, null, 2)}</code>
          </pre>
        </VStack>
      </SimpleGrid>
    </VStack>
  );
};

export { Debug };
