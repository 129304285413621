import { Box, BoxProps, Text } from "@chakra-ui/react";
import { vimeoVideo } from "../../types/activity";

interface VimeoProps extends BoxProps {
  video: vimeoVideo;
  height?: number | string; // This becomes optional, width drives the size
  width?: number | string;
}

const Vimeo: React.FC<VimeoProps> = ({ video, width = "100%", height }) => {
  if (
    window.location.hostname === "xsja.swimsmooth.guru" ||
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return (
      <Text color={"red.500"} fontWeight={"extrabold"}>
        Looks like you are on a test environment ({window.location.hostname}) so
        videos will not render!
      </Text>
    );
  }

  return (
    <Box width={width} position="relative" paddingBottom="56.25%">
      {" "}
      {/* 16:9 aspect ratio */}
      <iframe
        src={`https://player.vimeo.com/video/${video.id}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479`}
        allow="fullscreen; autoplay;"
        title={video.title ? video.title : "Watch"}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: "none",
        }}
      ></iframe>
    </Box>
  );
};

export default Vimeo;
