import { Heading, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { selectUserState } from "../../../app/features/user/userSlice";
import { useAppSelector } from "../../../app/hooks";
import Loading from "../../ui/Loading";

const AccountSettingsEmailAddress = () => {
  const { user } = useAppSelector(selectUserState);
  const warningTextColour = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );

  if (!user) {
    return <Loading message="Error Loading User" />;
  }

  return (
    <VStack w="full" alignItems="flex-start" mb={5}>
      <Heading as="h3" size="md">
        Login Email Address
      </Heading>
      <Text fontSize={"xs"} mt={-2} color={warningTextColour}>
        Your login email address cannot be changed, please contact support if
        this becomes an issue.
      </Text>
      <Text>{user.data.basic.emailAddress}</Text>
    </VStack>
  );
};

export { AccountSettingsEmailAddress };
