import {
  Box,
  BoxProps,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { vimeoVideo } from "../../types/activity";
import Vimeo from "./Vimeo";

interface VimeoContainerProps extends BoxProps {
  video: vimeoVideo;
  title: string;
  description?: string;
  videoWidth?: string | number; // Optional for responsive design
  videoHeight?: string | number; // Optional for aspect ratio-based layout
}

const VimeoContainer: React.FC<VimeoContainerProps> = ({
  video,
  title,
  description,
  videoWidth = "100%",
  videoHeight = "auto", // Let aspect ratio handle height if unspecified
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );

  return (
    <Box bg={boxColorMode} w="full" p={4} borderRadius={10} overflow="hidden">
      <Heading as="h4" size="sm" mb={4}>
        {title ? "Watch: " + title : "Watch"}
      </Heading>
      <SimpleGrid
        columns={[1, 1, 3]} // Adjust grid columns responsively
        spacing={4}
      >
        <Box w={videoWidth} overflow="hidden">
          <Vimeo video={video} width={videoWidth} height={videoHeight} />
        </Box>
        {description && (
          <GridItem colSpan={[1, 1, 2]}>
            <Text>{description}</Text>
          </GridItem>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default VimeoContainer;
