import { Box, BoxProps, Button, Center, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getActivities } from "../../DataAccess/activities";
import {
  loadSize,
  selectActivitiesState,
  updateActivitiesState,
} from "../../app/features/activity/activitiesSlice";
import { updateActivityState } from "../../app/features/activity/activitySlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { activity } from "../../types/activity";
import Loading from "../ui/Loading";
import { ActivityListItem } from "./ActivityListItem";

interface ActivityListProps extends BoxProps {}

const ActivityList: React.FC<ActivityListProps> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { activities, lastActivity, lastLoad } = useAppSelector(
    selectActivitiesState
  );
  const dispatch = useAppDispatch();

  const getActivitiesLocal = async () => {
    try {
      const { activities, last } = await getActivities();
      dispatch(
        updateActivitiesState({
          lastActivity: last,
          lastLoad: activities.length,
          activities: activities,
        })
      );
      setLoading(false);
    } catch (error) {
      console.log("Caught a good ol' error here", error);
    }
  };

  useEffect(() => {
    getActivitiesLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // I'm finding this more annoyuing that good!
  // useEffect(() => {
  //   if (activity) {
  //     const scrollTo = document.getElementById('ACTIVITY_' + activity.id);
  //     scrollTo?.scrollIntoView();
  //   }
  // });

  if (loading) {
    return <Loading />;
  }

  const nextPage = () => {
    setLoadingMore(true);
    dispatch(updateActivityState(null));
    const getActivitiesLocal = async () => {
      const { activities: newActivities, last } = await getActivities(
        lastActivity
      );
      const combinedActivities = [...activities, ...newActivities];
      dispatch(
        updateActivitiesState({
          lastActivity: last,
          lastLoad: newActivities.length,
          activities: combinedActivities,
        })
      );
      setLoading(false);
      setLoadingMore(false);
    };
    getActivitiesLocal();
  };

  if (activities.length === 0) {
    return (
      <Box w="full">
        <Text>No activities found</Text>
      </Box>
    );
  }

  return (
    <Box w="full">
      {activities.map((activity: activity) => {
        return <ActivityListItem key={activity.id} activity={activity} />;
      })}
      {lastLoad >= loadSize && (
        <Center>
          <Button isLoading={loadingMore} onClick={nextPage}>
            Load More
          </Button>
        </Center>
      )}
    </Box>
  );
};

export { ActivityList };
