import { Heading, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { default as Stripe } from "stripe";
import { selectAuthState } from "../../app/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loading from "../../components/ui/Loading";
import { getCheckoutSession } from "../../DataAccess/stripe";
import { checkRole, forceRefreshToken } from "../../utils/authHelper";

const AccountSubscriptionConfirmedPage = () => {
  const { access: accessToken = null } = useAppSelector(
    (state) => selectAuthState(state) || null
  );
  const { checkoutSessionId } = useParams<{
    checkoutSessionId: string;
  }>();
  const [loading, setLoading] = useState<Boolean>(true);
  const [checkoutSession, setCheckoutSession] =
    useState<Stripe.Checkout.Session | null>(null);
  const dispatch = useAppDispatch();

  const getCheckoutSessionLocal = async (checkoutSessionId: string) => {
    setCheckoutSession(await getCheckoutSession(checkoutSessionId));
  };

  const forceRefreshLocal = async () => {
    await forceRefreshToken(dispatch);
  };

  // Refresh User Object
  useEffect(() => {
    // Get the checkout session and update the users
    if (checkoutSessionId) {
      getCheckoutSessionLocal(checkoutSessionId);
    }
    forceRefreshLocal();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading message={"Saving subscription details"} />;
  }

  return (
    <VStack w={"full"}>
      <Heading>Thank you for subscribing</Heading>
      <Text>
        We're a small team passionate about improving your swimming, your
        coaching, your triathlon and you so thanks for putting your trust in us.
        You'll receive a series of emails over the coming week introducing you
        to all the features of the Guru but for now we'd suggesting heading to
        'Library' or 'Sessions' from the header menu and having a look around.
      </Text>
      {(process.env.REACT_APP_ENV === "alpha" ||
        checkRole(accessToken, "*ALL", "superadmin")) && (
        <pre>
          <code>{JSON.stringify(checkoutSession, null, 2)}</code>
        </pre>
      )}
    </VStack>
  );
};

export { AccountSubscriptionConfirmedPage };
