import {
  Box,
  BoxProps,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

interface TypeSelectorProps extends BoxProps {
  typeInput: string;
  subTypeInput: string;
  setTypeInput: any;
  setSubTypeInput: any;
}

const TypeSelector: React.FC<TypeSelectorProps> = ({
  typeInput,
  subTypeInput,
  setTypeInput,
  setSubTypeInput,
}) => {
  const boxColorMode = useColorModeValue(
    "ssBoxBackgroundLight",
    "ssBoxBackgroundDark"
  );
  const selectedBoxColorMode = useColorModeValue(
    "ssNeonOrangeLight",
    "ssNeonOrangeDark"
  );
  const backgroundColorMode = useColorModeValue(
    "ssBackgroundLight",
    "ssBackgroundDark"
  );

  const isSelectedBorder = (type: string, subType: string): number => {
    if (typeInput === type && subTypeInput === subType) {
      return 3;
    }
    return 3;
  };

  const isSelectedBorderRadius = (type: string, subType: string): number => {
    if (typeInput === type && subTypeInput === subType) {
      return 7;
    }
    return 10;
  };

  const isSelected = (type: string, subType: string): string => {
    if (typeInput === type && subTypeInput === subType) {
      return selectedBoxColorMode;
    }
    return backgroundColorMode;
  };

  const fixed = [
    [
      "Group",
      "Basics",
      "Swim Development Plan",
      "https://bb733c4fe3721ef7324b-ce45a0afce0c331a08b5b2c095400659.ssl.cf3.rackcdn.com/medialibrary/images/uncroppedpreviewimages/9a5382a4-155c-4092-b6cb-6e68bc1a0500.jpg",
    ],
  ];

  const events = [
    [
      "Event",
      "Triathlon",
      "Triathlon Plan",
      "https://d3n8k51hasny1.cloudfront.net/event-tri-large.jpg",
    ],
    [
      "Event",
      "Open",
      "Open Water Event Plan",
      "https://d3n8k51hasny1.cloudfront.net/event-ow-large.jpg",
    ],
    [
      "Event",
      "Pool",
      "Pool Event Plan",
      "https://d3n8k51hasny1.cloudfront.net/event-pool-large.jpg",
    ],
  ];
  return (
    <VStack w="full" alignItems="flex-start">
      <Heading as="h3" size="md">
        Specific Plans
      </Heading>
      <Text pb={2}>
        Specific plans work towards a common goal with everyone getting the same
        sessions to work through each week but tailored to you where
        appropriate.
      </Text>
      <SimpleGrid columns={[1, 1, 3, 3]} spacing={5} w="full">
        {fixed.map((type: any) => {
          return (
            <Box
              key={type[0] + type[1]}
              w="full"
              bg={boxColorMode}
              borderWidth={isSelectedBorder(type[0], type[1])}
              borderColor={isSelected(type[0], type[1])}
              borderRadius={10}
              boxSizing="border-box"
              cursor="pointer"
              onClick={() => {
                setTypeInput(type[0]);
                setSubTypeInput(type[1]);
              }}
            >
              <Image
                borderTopRadius={isSelectedBorderRadius(type[0], type[1])}
                src={type[3]}
              />
              <Text p={2} px={3}>
                {type[2]}
              </Text>
            </Box>
          );
        })}
      </SimpleGrid>
      <Heading as="h3" size="md" pt={5}>
        Work towards an event
      </Heading>
      <Text pb={2}>
        Reach your goals faster, with training plans that adapt to you based on
        your swimming. You'll need to be comfortable swimming at least 2-3km per
        session to get the most out of these plans.
      </Text>
      <SimpleGrid columns={[1, 1, 3, 3]} spacing={5} w="full">
        {events.map((type: any) => {
          return (
            <Box
              key={type[0] + type[1]}
              w="full"
              bg={boxColorMode}
              borderWidth={isSelectedBorder(type[0], type[1])}
              borderColor={isSelected(type[0], type[1])}
              borderRadius={10}
              boxSizing="border-box"
              cursor="pointer"
              onClick={() => {
                setTypeInput(type[0]);
                setSubTypeInput(type[1]);
              }}
            >
              <Image
                borderTopRadius={isSelectedBorderRadius(type[0], type[1])}
                src={type[3]}
              />
              <Text p={2} px={3}>
                {type[2]}
              </Text>
            </Box>
          );
        })}
      </SimpleGrid>
      <Heading as="h3" size="md" pt={5}>
        <em>COMING SOON</em> - Never Ending Plans
      </Heading>
      <Text pb={2}>
        For those who just want to be given something to swim each week! These
        plans never end and will continue to deliver a mix of Swim Smooth' world
        renowned sessions each week. You'll need to be comfortable swimming at
        least 2-3km per session to get the most out of these plans.
      </Text>
    </VStack>
  );
};

export { TypeSelector };
