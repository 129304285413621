import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { activityDataSummaryDates } from "../types/activity";
import { dp, lPadNumber, rPadNumber } from "./coreHelper";

const dayDescription = (day: number): string => {
  return (
    [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][day] || ""
  );
};

const formatDateTime = (date: string | Date): string => {
  let dateObject;
  if (typeof date === "string") {
    dateObject = new Date(date);
  } else {
    dateObject = date;
  }
  return dateObject.toLocaleDateString("en-GB", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

const formatDateTimeShort = (date: string | Date): string => {
  let dateObject;
  if (typeof date === "string") {
    dateObject = new Date(date);
  } else {
    dateObject = date;
  }
  return dateObject.toLocaleDateString("en-GB", {
    weekday: "short",
    year: "2-digit",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

const formatDate = (date: string | Date): string => {
  let dateObject;
  if (typeof date === "string") {
    dateObject = new Date(date);
  } else {
    dateObject = date;
  }
  return dateObject.toLocaleDateString("en-GB", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour12: false,
  });
};

const formatDateShort = (date: string | Date): string => {
  let dateObject;
  if (typeof date === "string") {
    dateObject = new Date(date);
  } else {
    dateObject = date;
  }
  return dateObject.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour12: false,
  });
};

const formatDateLong = (date: string | Date): string => {
  let dateObject;
  if (typeof date === "string") {
    dateObject = new Date(date);
  } else {
    dateObject = date;
  }
  return dateObject.toLocaleDateString("en-GB", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
  });
};

const formatTime = (time: number): string => {
  const timeString: string = time.toString();
  return timeString.substring(0, 2) + ":" + timeString.substring(2);
};

const formatDuration = (seconds: number, includeMillis?: boolean): string => {
  if (typeof includeMillis === "undefined") {
    includeMillis = false;
  }
  // Hours, minutes and seconds and millis
  const hrs = ~~(seconds / 3600);
  const remains = seconds - hrs * 3600;
  const mins = Math.floor(remains / 60);
  const secs = Math.floor(remains % 60);
  const millis = dp(((remains % 60) - secs) * 100, 0);

  let ret = lPadNumber(mins) + ":" + lPadNumber(secs);
  if (hrs > 0) {
    ret = lPadNumber(hrs) + ":" + ret;
  }
  if (includeMillis) {
    ret = ret + "." + rPadNumber(millis);
  }
  return ret;
};

const formatDurationLong = (
  seconds: number,
  includeMillis?: boolean
): string => {
  if (typeof includeMillis === "undefined") {
    includeMillis = false;
  }
  // Hours, minutes and seconds and millis
  const hrs = ~~(seconds / 3600);
  const remains = seconds - hrs * 3600;
  const mins = Math.floor(remains / 60);
  const secs = Math.floor(remains % 60);
  const millis = dp(((remains % 60) - secs) * 100, 0);

  let ret = lPadNumber(mins) + "m " + lPadNumber(secs);
  if (hrs > 0) {
    ret = lPadNumber(hrs) + "h " + ret;
  }
  if (includeMillis) {
    ret = ret + "." + rPadNumber(millis) + "s";
  } else {
    ret = ret + "s";
  }
  return ret;
};

const formatPace = (seconds: number): string => {
  // minutes and seconds and millis
  const positiveSeconds = Math.abs(seconds);

  const mins = Math.floor(positiveSeconds / 60);
  const secs = Math.floor(positiveSeconds % 60);
  const millis = dp(((positiveSeconds % 60) - secs) * 100, 0);

  let ret =
    lPadNumber(mins) + ":" + lPadNumber(secs) + "." + lPadNumber(millis);
  return ret;
};

const formatPaceChange = (seconds: number): string => {
  // minutes and seconds and millis
  const originalSeconds = seconds;
  const positiveSeconds = Math.abs(seconds);

  const mins = Math.floor(positiveSeconds / 60);
  const secs = Math.floor(positiveSeconds % 60);
  const millis = dp(((positiveSeconds % 60) - secs) * 100, 0);

  let ret =
    (originalSeconds < 0 ? "-" : "+") +
    lPadNumber(mins) +
    ":" +
    lPadNumber(secs) +
    "." +
    lPadNumber(millis);
  return ret;
};

const splitDuration = (seconds: number): any => {
  const hrs = Math.floor(seconds / 3600);
  const min = Math.floor((seconds - hrs * 3600) / 60);
  const sec = Math.floor(seconds - hrs * 3600 - min * 60);
  return {
    hrs,
    min,
    sec,
  };
};

const splitSeconds = (seconds: number): any => {
  const min = Math.floor(seconds / 60);
  const sec = Math.floor(seconds - min * 60);
  const mil = dp((seconds - min * 60 - sec) * 100, 0);
  return {
    min,
    sec,
    mil,
  };
};

const getPreviousMonday = (date: Date) => {
  const previousMonday = new Date(date.valueOf());
  previousMonday.setDate(
    previousMonday.getDate() - ((previousMonday.getDay() + 6) % 7)
  );
  previousMonday.setHours(0, 0, 0, 0);
  return previousMonday;
};

const getNextMonday = (date: Date) => {
  const nextMonday = new Date(date.valueOf());
  nextMonday.setDate(
    nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
  );
  nextMonday.setHours(0, 0, 0, 0);
  return nextMonday;
};

const getNextSunday = (date: Date) => {
  const nextSunday = new Date(date.valueOf());
  nextSunday.setDate(
    nextSunday.getDate() + ((1 + 6 - nextSunday.getDay()) % 7 || 0)
  );
  nextSunday.setHours(23, 59, 59, 999);
  return nextSunday;
};

const getActivityStartDate = (activityDate: activityDataSummaryDates): Date => {
  console.log(activityDate.start, activityDate.offset);
  return toZonedTime(activityDate.start, activityDate.offset);
};

const getActivityEndDate = (activityDate: activityDataSummaryDates): Date => {
  console.log(activityDate.start, activityDate.offset);
  return toZonedTime(activityDate.end, activityDate.offset);
};

const outputActivityDate = (activityDate: activityDataSummaryDates): string => {
  try {
    return (
      format(getActivityStartDate(activityDate), "eeee do MMMM yyyy @ HH:mm") +
      " - " +
      format(getActivityEndDate(activityDate), " HH:mm") +
      " (" +
      activityDate.timezone +
      ")"
    );
  } catch (error) {
    console.log(error);
    return "Invalid Date";
  }
};

const outputActivityDateShort = (
  activityDate: activityDataSummaryDates
): string => {
  try {
    return (
      format(getActivityStartDate(activityDate), "eee do MMM yyyy @ HH:mm") +
      " (" +
      activityDate.timezone +
      ")"
    );
  } catch (error) {
    return "Invalid Date";
  }
};

export {
  dayDescription,
  formatDate,
  formatDateLong,
  formatDateShort,
  formatDateTime,
  formatDateTimeShort,
  formatDuration,
  formatDurationLong,
  formatPace,
  formatPaceChange,
  formatTime,
  getActivityEndDate,
  getActivityStartDate,
  getNextMonday,
  getNextSunday,
  getPreviousMonday,
  lPadNumber,
  outputActivityDate,
  outputActivityDateShort,
  rPadNumber,
  splitDuration,
  splitSeconds,
};
