import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { activity } from "../../../types/activity";
import { RootState } from "../../store";

export const loadSize = 25;
// Define the state of the slice as an object
export interface ActivitiesState {
  lastUpdated: string;
  lastLoad: number;
  lastActivity: string;
  activities: activity[];
}

export interface ActivitiesStateUpdate {
  lastActivity: string;
  lastLoad: number;
  activities: activity[];
}

// Define an initial state
const initialState: ActivitiesState = {
  lastUpdated: new Date("1970-01-01T00:00:00.000").toISOString(),
  lastLoad: loadSize,
  lastActivity: "",
  activities: [],
};

// Create a slice containing the configuration of the state
// and the reducers functions
const activitiesSlice = createSlice({
  name: "activitiesState",
  initialState,
  reducers: {
    updateActivitiesState(
      state,
      action: PayloadAction<ActivitiesStateUpdate | null>
    ) {
      if (action.payload) {
        state.lastUpdated = new Date().toISOString();
        state.lastLoad = action.payload.lastLoad;
        state.lastActivity = action.payload.lastActivity;
        state.activities = action.payload.activities;
      } else {
        state.lastUpdated = initialState.lastUpdated;
        state.lastLoad = initialState.lastLoad;
        state.lastActivity = initialState.lastActivity;
        state.activities = initialState.activities;
      }
    },
    removeActivityFromState(state, action: PayloadAction<string>) {
      state.activities = state.activities.filter(
        (activity: activity) => activity.id !== action.payload
      );
    },
  },
});

// Export each reducers function defined in createSlice
export const updateActivitiesState =
  activitiesSlice.actions.updateActivitiesState;
export const removeActivityFromState =
  activitiesSlice.actions.removeActivityFromState;

export const selectActivitiesState = (state: RootState): ActivitiesState => {
  return state.activitiesState;
};

// Export default the slice reducer
export default activitiesSlice.reducer;
