import {
  BoxProps,
  Button,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Stripe from "stripe";
import {
  selectUserState,
  updateUserState,
} from "../../../../app/features/user/userSlice";
import { useAppSelector } from "../../../../app/hooks";
import {
  createUserStripeCustomerSession,
  setUserStripeCustomer,
  setUserStripeSubscription,
} from "../../../../DataAccess/stripe";
import { getLoggedInUser } from "../../../../DataAccess/users";
import { breadcrumbLink } from "../../../../types/breadcrumb";
import { forceRefreshToken } from "../../../../utils/authHelper";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../../context/BreadcrumbContext";
import { MessageDisplay } from "../../../generic/MessageDisplay";
import Loading from "../../../ui/Loading";

interface SubscribeStripeProps extends BoxProps {}

const StripePricingTable: React.FC<SubscribeStripeProps> = () => {
  const { user } = useAppSelector(selectUserState);
  const [restoringPurchases, setRestoringPurchases] = useState<boolean>(false);

  const forceRefreshLocal = async () => {
    await forceRefreshToken(dispatch);
  };

  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [loadingStripeCustomerSession, setLoadingStripeCustomerSession] =
    useState<boolean>(true);
  const [stripeScriptLoaded, setStripeScriptLoaded] = useState(false);
  const [stripeCustomerSession, setStripeCustomerSession] =
    useState<Stripe.CustomerSession | null>(null);
  const toast = useToast();
  const dispatch = useDispatch();

  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const pricingTableId = useColorModeValue(
    process.env.REACT_APP_STRIPE_PT_LIGHT,
    process.env.REACT_APP_STRIPE_PT_DARK
  );

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/account", title: "Account" });
    breadcrumbLinks.push({
      href: "/account/subscription",
      title: "Subscription",
    });
    breadcrumbLinks.push({
      href: "/account/subscription/subscribe",
      title: "Subscribe",
    });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    script.onload = () => setStripeScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const getUserAndCreateSessionLocal = async () => {
      try {
        // Get customer session from Stripe (may update the user)
        const session = await createUserStripeCustomerSession();
        if (!session || !session.client_secret) {
          throw new Error("Invalid Stripe Customer Session");
        }
        console.log("Customer Session Retrieved");
        setStripeCustomerSession(session);
        setLoadingStripeCustomerSession(false);
        // Get latest user object for logged in user
        const user = await getLoggedInUser();
        if (!user) {
          throw new Error("Error loading user");
        }
        console.log("User Retrieved");
        dispatch(updateUserState(user));
        setLoadingUser(false);
      } catch (error) {
        console.error("Error fetching Stripe session:", error);
        setLoadingStripeCustomerSession(false);
        setLoadingUser(false);
      }
    };
    getUserAndCreateSessionLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!stripeScriptLoaded) {
    return <Loading message="Loading Stripe Pricing Table" />;
  }

  if (loadingStripeCustomerSession) {
    return <Loading message="Getting Stripe Session" />;
  }

  if (!stripeCustomerSession) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not load billing session">
          <Text>
            Unable to create billing session with Stripe. Please try again
            later.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (!stripeCustomerSession?.client_secret) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not load billing session">
          <Text>
            Unable to create billing session with Stripe. Please try again
            later.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (loadingUser) {
    return <Loading message="Loading User" />;
  }

  if (!user) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not load user">
          <Text>Unable to retrieve user details. Please try again later.</Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (!user || !user.data?.billing?.stripeCustomer) {
    return (
      <VStack w="full">
        <MessageDisplay status="error" title="Could not load user">
          <Text>
            Unable to retrieve Stripe customer. Please try again later.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  return (
    <VStack w="full">
      {React.createElement("stripe-pricing-table", {
        "pricing-table-id": pricingTableId,
        "publishable-key": process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
        style: { width: "100%" },
        "customer-session-client-secret": stripeCustomerSession.client_secret,
      })}
      <Button
        isLoading={restoringPurchases}
        onClick={async () => {
          toast.closeAll();
          try {
            setRestoringPurchases(true);
            const customerId = await setUserStripeCustomer(user._id);
            console.log(customerId);
            const subscriptionId = await setUserStripeSubscription(user._id);
            console.log(subscriptionId);
            forceRefreshLocal();
            setRestoringPurchases(false);
          } catch (error) {
            console.log(error);
          }
        }}
      >
        Restore Purchases
      </Button>
      <Text mt={3} fontSize={"xx-small"}>
        {user.data.basic.firstName} / {user.data.basic.lastName} /{" "}
        {user.data.basic.fullName
          ? user.data.basic.fullName
          : "GENERATED: " +
            user.data.basic.firstName.trim() +
            " " +
            user.data.basic.lastName.trim()}{" "}
        / {user.data.basic.emailAddress} / {user._id} /{" "}
        {user.data.billing.stripeCustomer}
      </Text>
    </VStack>
  );
};

export { StripePricingTable };
