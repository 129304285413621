import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";
import { user, userSubscribe } from "../types/user";

const getLoggedInUser = async (): Promise<user | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "users/"
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const getUserById = async (userId: string): Promise<user | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "users/id/" + userId
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const getUserByEmail = async (email: string): Promise<user | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "users/email/" + email
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const getUserFloDeskDataById = async (userId: string): Promise<user | null> => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_ROOT_URL + "users/id/" + userId + "/flodesk"
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    console.log(error);
    throw new Error(processSwimSmoothApiError(error));
  }
  return null;
};

const subscribeUser = async (data: userSubscribe) => {
  try {
    const result = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "users/subscribe",
      {
        affiliateId: data.affiliateId,
        invitationId: data.invitationId,
        emailAddress: data.emailAddress,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        gdprRequired: data.gdprRequired ? 1 : 0,
        gdprMailing: data.gdprMailing ? 1 : 0,
        gdprMarketing: data.gdprMarketing ? 1 : 0,
        hToken: data.hToken,
        captchaSource: "H",
      }
    );
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const updateUser = async (userId: string, data: any): Promise<user> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "users/" + userId,
      data
    );
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const updateEmailAddress = async (userId: string, data: any): Promise<user> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "users/email",
      data
    );
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const onboardUser = async (data: any): Promise<user> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "users/onboard",
      data
    );
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const updateUserConsent = async (data: any): Promise<user> => {
  try {
    const result = await axios.patch(
      process.env.REACT_APP_API_ROOT_URL + "users/consent",
      data
    );
    return result.data.payload;
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

const deleteUser = async (): Promise<void> => {
  try {
    await axios.delete(process.env.REACT_APP_API_ROOT_URL + "users/");
  } catch (error: any) {
    throw new Error(processSwimSmoothApiError(error));
  }
};

export {
  deleteUser,
  getLoggedInUser,
  getUserByEmail,
  getUserById,
  getUserFloDeskDataById,
  onboardUser,
  subscribeUser,
  updateEmailAddress,
  updateUser,
  updateUserConsent,
};
