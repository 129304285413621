import {
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { selectAuthState } from "../../../app/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getForgottenPasswordAdmin,
  resetPasswordAdmin,
} from "../../../DataAccess/passwords";
import {
  setUserStripeCustomer,
  setUserStripeSubscription,
} from "../../../DataAccess/stripe";
import { getUserByEmail } from "../../../DataAccess/users";
import { user } from "../../../types/user";
import { checkRole, swapToken } from "../../../utils/authHelper";
import { formatDateTimeShort } from "../../../utils/dateHelper";
import { createToast } from "../../../utils/toastHelper";
import { CustomToast } from "../../ui/CustomToast";
import { SingleValueDisplay } from "../../ui/SingleValueDisplay";
import { SingleValueWithUnitDisplay } from "../../ui/SingleValueWithUnitDisplay";

const UserLookup = () => {
  const { access: accessToken, refresh: refreshToken } =
    useAppSelector(selectAuthState);
  const [actionResult, setActionResult] = useState<string | null>();
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [swapping, setSwapping] = useState<boolean>(false);
  const [gettingPasswordLink, setGettingPasswordLink] =
    useState<boolean>(false);
  const [resettingPassword, setResettingPassword] = useState<boolean>(false);
  const [user, setUser] = useState<user | null>();
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const handleToggle = () => setShow(!show);

  const { control: controlBasic, handleSubmit: handleSubmitBasic } = useForm({
    defaultValues: {
      searchEmail: "",
    },
    mode: "all",
  });

  const handleBasic = async (data: any): Promise<void> => {
    if (data.searchEmail) {
      setSearching(true);
      setSearchPerformed(false);
      try {
        const user = await getUserByEmail(data.searchEmail);
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
      } catch (error) {
        setUser(null);
      }
      setSearchPerformed(true);
      setSearching(false);
    } else {
      setSearching(true);
      setUser(null);
      setSearchPerformed(false);
      setSearching(false);
    }
  };

  if (!checkRole(accessToken, "*ALL", "admin")) {
    return null;
  }

  return (
    <VStack w={"full"}>
      <Grid
        templateColumns="repeat(1, 1fr)"
        gap={2}
        mb={4}
        as="form"
        onSubmit={handleSubmitBasic(handleBasic)}
        w="full"
        alignItems="flex-start"
      >
        <FormControl>
          <Controller
            control={controlBasic}
            name="searchEmail"
            render={({ field: { ref, ...restField } }) => (
              <Input {...restField} placeholder="Search by email address" />
            )}
          />
        </FormControl>
        <Button type="submit" isLoading={searching}>
          Search
        </Button>
      </Grid>
      {searchPerformed && user && (
        <VStack w={"full"} alignItems={"flex-start"}>
          <Heading as={"h3"} size={"md"}>
            Required
          </Heading>
          <SimpleGrid columns={4} rowGap={2} w={"full"}>
            <SingleValueDisplay label={"ID"} value={user._id} />
            <SingleValueDisplay
              label={"Email Address"}
              value={user.data.basic.emailAddress}
            />
            <GridItem colSpan={2}>
              <SingleValueDisplay
                label={"Previous Email Addresses"}
                value={
                  user.data.basic.previousEmailAddresses
                    ? user.data.basic.previousEmailAddresses?.join(", ")
                    : "None"
                }
              />
            </GridItem>
          </SimpleGrid>
          <Heading as={"h3"} size={"md"}>
            Onboarding
          </Heading>
          <SimpleGrid columns={4} rowGap={2} w={"full"}>
            <SingleValueDisplay
              label={"First Name"}
              value={user.data.basic.firstName}
            />
            <SingleValueDisplay
              label={"Last Name"}
              value={user.data.basic.lastName}
            />
            <SingleValueWithUnitDisplay
              label={"Default Pool"}
              value={user.data.basic.defaultPool.lapLength}
              unit={user.data.basic.defaultPool.lapUnit}
            />
          </SimpleGrid>
          <Heading as={"h3"} size={"md"}>
            Billing
          </Heading>
          <SimpleGrid columns={4} rowGap={2} w={"full"}>
            <SingleValueDisplay
              label={"Source"}
              value={user.data.billing.source}
            />
            <SingleValueDisplay
              label={"Stripe Customer ID"}
              value={
                user.data.billing.stripeCustomer
                  ? user.data.billing.stripeCustomer
                  : "Not Set"
              }
            />
          </SimpleGrid>
          <Heading as={"h3"} size={"md"}>
            Subscription
          </Heading>
          <SimpleGrid columns={4} rowGap={2} w={"full"}>
            <SingleValueDisplay
              label={"Subscription ID"}
              value={
                user.data.billing.subscription?.id
                  ? user.data.billing.subscription?.id
                  : "Not Set"
              }
            />
            <SingleValueDisplay
              label={"Subscription Status"}
              value={user.data.billing.subscriptionStatus}
            />
            <SingleValueDisplay
              label={"Level"}
              value={user.data.billing.level}
            />
          </SimpleGrid>
          <SimpleGrid columns={4} rowGap={2} w={"full"}>
            {user.data.billing.subscription && (
              <SingleValueDisplay
                label={"Current Period Start Date"}
                value={formatDateTimeShort(
                  user.data.billing.subscription.currentPeriodStart
                )}
              />
            )}
            {user.data.billing.subscription && (
              <SingleValueDisplay
                label={"Current Period End Date"}
                value={formatDateTimeShort(
                  user.data.billing.subscription.currentPeriodEnd
                )}
              />
            )}
            {user.data.billing.subscription && (
              <SingleValueDisplay
                label={"Cancelled"}
                value={formatDateTimeShort(
                  user.data.billing.subscription.cancelled.toString()
                )}
              />
            )}
          </SimpleGrid>
          <Heading as={"h3"} size={"md"}>
            Trial
          </Heading>
          <SimpleGrid columns={4} rowGap={2} w={"full"}>
            <SingleValueDisplay
              label={"Trial Status"}
              value={user.data.billing.trialStatus}
            />
            {user.data.billing.trial && (
              <SingleValueDisplay
                label={"Provider"}
                value={user.data.billing.trial.provider}
              />
            )}
          </SimpleGrid>
          <SimpleGrid columns={4} rowGap={2} w={"full"}>
            {user.data.billing.trial && (
              <SingleValueDisplay
                label={"Start Date"}
                value={formatDateTimeShort(user.data.billing.trial.startDate)}
              />
            )}
            {user.data.billing.trial && (
              <SingleValueDisplay
                label={"Routine End Date"}
                value={formatDateTimeShort(
                  user.data.billing.trial.routineEndDate
                )}
              />
            )}
            {user.data.billing.trial && (
              <SingleValueDisplay
                label={"Understanding End Date"}
                value={formatDateTimeShort(
                  user.data.billing.trial.understandingEndDate
                )}
              />
            )}
          </SimpleGrid>
          {user.last && (
            <>
              <Heading as={"h3"} size={"md"}>
                Last
              </Heading>
              <SimpleGrid columns={4} rowGap={2} w={"full"}>
                {user.last.login && (
                  <SingleValueDisplay
                    label={"Login"}
                    value={formatDateTimeShort(user.last.login)}
                  />
                )}
                {user.last.refresh && (
                  <SingleValueDisplay
                    label={"Refresh"}
                    value={formatDateTimeShort(user.last.refresh)}
                  />
                )}
                {user.last.passwordChange && (
                  <SingleValueDisplay
                    label={"Password Change"}
                    value={formatDateTimeShort(user.last.passwordChange)}
                  />
                )}
              </SimpleGrid>
            </>
          )}
          <Heading as={"h3"} size={"md"}>
            Actions
          </Heading>
          <HStack>
            <Button
              variant={"success"}
              onClick={async () => {
                toast.closeAll();
                try {
                  if (refreshToken) {
                    setSwapping(true);
                    await swapToken(dispatch, user._id);
                    navigate("/", { replace: false });
                  } else {
                    setSwapping(false);
                    throw new Error("Unable to login as selected user");
                  }
                } catch (error) {
                  setSwapping(false);
                  createToast(toast, (props: any) => {
                    return (
                      <CustomToast
                        title={"Login As"}
                        status={"Error"}
                        toast={toast}
                        toastId={props.id}
                      >
                        <Text>Unable to login as selected user</Text>
                      </CustomToast>
                    );
                  });
                }
              }}
              isLoading={swapping}
            >
              Login As
            </Button>
            <Button
              onClick={async () => {
                toast.closeAll();
                try {
                  setGettingPasswordLink(true);
                  const otc = await getForgottenPasswordAdmin(
                    user.data.basic.emailAddress
                  );
                  setActionResult(
                    process.env.REACT_APP_CALLBACK_ROOT_URL +
                      "password/reset?otc=" +
                      otc
                  );
                  createToast(toast, (props: any) => {
                    return (
                      <CustomToast
                        title={"Get Reset Password Link"}
                        status={"Success"}
                        toast={toast}
                        toastId={props.id}
                      >
                        <Text>Password link generated, see action result</Text>
                      </CustomToast>
                    );
                  });
                  setGettingPasswordLink(false);
                } catch (error) {
                  setGettingPasswordLink(false);
                  createToast(toast, (props: any) => {
                    return (
                      <CustomToast
                        title={"Get Reset Password Link"}
                        status={"Error"}
                        toast={toast}
                        toastId={props.id}
                      >
                        <Text>
                          Unable to get reset password link for selected user
                        </Text>
                      </CustomToast>
                    );
                  });
                }
              }}
              isLoading={gettingPasswordLink}
            >
              Generate Reset Password Link
            </Button>
            <Button
              variant={"warning"}
              onClick={async () => {
                toast.closeAll();
                try {
                  setResettingPassword(true);
                  const newPassword = await resetPasswordAdmin(user._id);
                  setActionResult(newPassword);
                  createToast(toast, (props: any) => {
                    return (
                      <CustomToast
                        title={"Get Reset Password Link"}
                        status={"Success"}
                        toast={toast}
                        toastId={props.id}
                      >
                        <Text>Password reset, see action result</Text>
                      </CustomToast>
                    );
                  });
                  setResettingPassword(false);
                } catch (error) {
                  console.log(error);
                  setResettingPassword(false);
                  createToast(toast, (props: any) => {
                    return (
                      <CustomToast
                        title={"Reset Password"}
                        status={"Error"}
                        toast={toast}
                        toastId={props.id}
                      >
                        <Text>Unable to reset password for selected user</Text>
                      </CustomToast>
                    );
                  });
                }
              }}
              isLoading={resettingPassword}
            >
              Reset Password
            </Button>
            {!user.data.billing.stripeCustomer && (
              <Button
                variant={"warning"}
                onClick={async () => {
                  toast.closeAll();
                  try {
                    const customerId = await setUserStripeCustomer(user._id);
                    console.log(customerId);
                    setSearching(true);
                    setSearchPerformed(false);
                    try {
                      const updatedUser = await getUserByEmail(
                        user?.data.basic.emailAddress
                      );
                      if (updatedUser) {
                        setUser(updatedUser);
                      } else {
                        setUser(null);
                      }
                    } catch (error) {
                      setUser(null);
                    }
                    setSearchPerformed(true);
                    setSearching(false);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Get Stripe Customer
              </Button>
            )}
            {user.data.billing.stripeCustomer &&
              !user.data.billing.subscription && (
                <Button
                  variant={"warning"}
                  onClick={async () => {
                    toast.closeAll();
                    try {
                      const subscriptionId = await setUserStripeSubscription(
                        user._id
                      );
                      console.log(subscriptionId);
                      setSearching(true);
                      setSearchPerformed(false);
                      try {
                        const updatedUser = await getUserByEmail(
                          user?.data.basic.emailAddress
                        );
                        if (updatedUser) {
                          setUser(updatedUser);
                        } else {
                          setUser(null);
                        }
                      } catch (error) {
                        setUser(null);
                      }
                      setSearchPerformed(true);
                      setSearching(false);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Get Stripe Subscription
                </Button>
              )}
          </HStack>
          {actionResult && (
            <code>
              <pre>{actionResult}</pre>
            </code>
          )}
          <Heading as={"h3"} size={"md"} mt={20}>
            Raw User Data
          </Heading>
          <Text>Mostly this is for Jon ... but feel free to have a nosey!</Text>
          <Box w={"full"}>
            <Collapse startingHeight={100} in={show}>
              <code>
                <pre>{JSON.stringify(user, null, 2)}</pre>
              </code>
            </Collapse>
            <Button onClick={handleToggle} mt={2}>
              Show {show ? "Less" : "More"}
            </Button>
          </Box>
        </VStack>
      )}
      {searchPerformed && !user && <Text>User not found</Text>}
      {!searchPerformed && (
        <Text>Enter a users email address above to lookup</Text>
      )}
    </VStack>
  );
};

export { UserLookup };
